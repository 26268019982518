<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
        <a-row :gutter="24">
          <a-col :span="6" v-for="item in otherFields" :key="item.value">
            <a-form-item label="学生" v-if="item.value === 'name'">
              <a-input
                v-model="queryParam.name"
                allowClear
                placeholder="姓名或学号"
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="手机" v-if="item.value === 'mobile'">
              <a-input
                v-model="queryParam.mobile"
                allowClear
                placeholder="请输入"
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="邮箱" v-if="item.value === 'email'">
              <a-input
                v-model="queryParam.email"
                allowClear
                placeholder="请输入"
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="时区" v-if="item.value === 'timezones'">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="请选择"
                mode="multiple"
                @change="timezonesChange"
              >
                <a-select-option v-for="(item, index) in optionsArr.timezone" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="国别号" v-if="item.value === 'countryCode'">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="queryParam.countryCode"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in optionsArr.countryCode" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="国家/地区" v-if="item.value === 'countryStateCities'">
              <LocationTreeSelect v-model="queryParam.countryStateCities" />
            </a-form-item>
            <a-form-item label="沟通语言" v-if="item.value === 'communicationLanguages'">
              <a-select placeholder="请选择" v-model="queryParam.communicationLanguages" mode="multiple">
                <a-select-option :value="item.value" v-for="(item, index) in languageArr" :key="index">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              label="性别"
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              v-if="item.value === 'gender'"
            >
              <a-select v-model="queryParam.gender" placeholder="请选择">
                <a-select-option v-for="(item, index) in optionsArr.gender" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="分销大使" v-if="item.value === 'reseller'">
              <a-select v-model="queryParam.reseller" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.reseller" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="生日" v-if="item.value === 'birthDate'">
              <a-range-picker @change="onChangeBirthTime" v-model="birthTimeArr" />
            </a-form-item>
            <a-form-item label="当前年龄" v-if="item.value === 'currentAge'">
              <a-input-number style="width: 100%" v-model="queryParam.currentAge" placeholder="请输入" />
            </a-form-item>
            <a-form-item label="中文学生状态" v-if="item.value === 'chineseStatus'">
              <a-select v-model="queryParam.chineseStatusSet" placeholder="请选择" mode="multiple" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.chineseStatus" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="中文剩余课时" v-if="item.value === 'chineseRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="中文剩余额度" v-if="item.value === 'chineseSharedRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseSharedRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseSharedRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="中文当前级别" v-if="item.value === 'currentChineseLevel'">
              <a-cascader
                :field-names="{ label: 'name', value: 'code', children: 'items' }"
                :options="optionsArr.currentChineseLevel"
                placeholder="请选择"
                @change="onChangeChineseLevel"
                v-model="currentChineseLevelArr"
              />
            </a-form-item>
            <a-form-item label="班主任" v-if="item.value === 'classAdminId'">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                @search="searchHeadmaster"
                v-model="queryParam.classAdminId"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in headmasterList" :key="index" :value="item.uuid">{{
                  item.fullName
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="中文学习规划师" v-if="item.value === 'chineseConsultant'">
              <a-select
                show-search
                allowClear
                @search="searchWXTeacher"
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="queryParam.chineseConsultant"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in teacherList" :key="index" :value="item.uuid">{{
                  item.fullName
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="中文推荐赠课" v-if="item.value === 'chineseGiftQrCode'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseGiftQrCodeStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseGiftQrCodeEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>

            <a-form-item label="中文购买课时" v-if="item.value === 'chinesePurchased'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chinesePurchasedStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chinesePurchasedEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="中文社交赠课" v-if="item.value === 'chineseGiftSocialNetworkShare'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseGiftSocialNetworkShareStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.chineseCourseUnit.chineseGiftSocialNetworkShareEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="中文班型" v-if="item.value === 'currentChineseClassType'">
              <a-select v-model="queryParam.currentChineseClassType" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="(item, index) in optionsArr.chineseClassType"
                  :key="index"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="中文班级" v-if="item.value === 'currentChineseClassName'">
              <a-input
                v-model="queryParam.currentChineseClassName"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="中文入学时间" v-if="item.value === 'chineseEntranceDate'">
              <a-range-picker @change="onChangeChineseEntranceDate" v-model="chineseEntranceDateArr" allowClear />
            </a-form-item>
            <a-form-item label="中文首课时间" v-if="item.value === 'chineseFirstLessonDate'">
              <a-range-picker @change="onChangeChineseFirstLessonDate" v-model="chineseFirstLessonDateArr" allowClear />
            </a-form-item>
            <a-form-item label="数学学生状态" v-if="item.value === 'mathStatus'">
              <a-select v-model="queryParam.mathStatusSet" placeholder="请选择" mode="multiple" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.mathStatus" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数学剩余课时" v-if="item.value === 'mathRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学剩余额度" v-if="item.value === 'mathSharedRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathSharedRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathSharedRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学当前级别" v-if="item.value === 'currentMathLevel'">
              <a-cascader
                allowClear
                :field-names="{ label: 'name', value: 'code', children: 'items' }"
                :options="optionsArr.currentMathLevel"
                placeholder="请选择"
                @change="onChangeMathLevel"
                v-model="currentMathLevelArr"
              />
            </a-form-item>
            <a-form-item label="数学学习规划师" v-if="item.value === 'mathConsultant'">
              <a-select
                v-model="queryParam.mathConsultant"
                show-search
                allowClear
                @search="searchWXTeacher"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in teacherList" :key="index" :value="item.uuid">{{
                  item.fullName
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数学推荐赠课" v-if="item.value === 'mathGiftQrCode'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathGiftQrCodeStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathGiftQrCodeEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学购买课时" v-if="item.value === 'mathPurchased'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathPurchasedStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathPurchasedEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学社交赠课" v-if="item.value === 'mathGiftSocialNetworkShare'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathGiftSocialNetworkShareStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.mathCourseUnit.mathGiftSocialNetworkShareEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学班型" v-if="item.value === 'currentMathClassType'">
              <a-select v-model="queryParam.currentMathClassType" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.mathClassType" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数学班级" v-if="item.value === 'currentMathClassName'">
              <a-input
                v-model="queryParam.currentMathClassName"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="数学入学时间" v-if="item.value === 'mathEntranceDate'">
              <a-range-picker @change="onChangeMathEntranceDate" v-model="mathEntranceDateArr" allowClear />
            </a-form-item>
            <a-form-item label="数学首课时间" v-if="item.value === 'mathFirstLessonDate'">
              <a-range-picker @change="onChangeMathFirstLessonDate" v-model="mathFirstLessonDateArr" allowClear />
            </a-form-item>
            <a-form-item label="沟通方式偏好" v-if="item.value === 'preferCommunicateWay'">
              <a-select v-model="queryParam.preferCommunicateWay" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="(item, index) in optionsArr.preferCommunicateWay"
                  :key="index"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="中文补升权益" v-if="item.value === 'chineseLevelUp'">
              <a-select v-model="queryParam.chineseLevelUp" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.chineseLevelUp" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数学补升权益" v-if="item.value === 'mathLevelUp'">
              <a-select v-model="queryParam.mathLevelUp" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.mathLevelUp" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="中文作业偏好" v-if="item.value === 'chineseHomeworkPrefer'">
              <a-select v-model="queryParam.chineseHomeworkPrefer" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="(item, index) in optionsArr.homeworkPreferType"
                  :key="index"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="数学作业偏好" v-if="item.value === 'mathHomeworkPrefer'">
              <a-select v-model="queryParam.mathHomeworkPrefer" placeholder="请选择" allowClear>
                <a-select-option
                  v-for="(item, index) in optionsArr.homeworkPreferType"
                  :key="index"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>

            <a-form-item label="英文学生状态" v-if="item.value === 'englishStatus'">
              <a-select v-model="queryParam.englishStatusSet" placeholder="请选择" mode="multiple" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.englishStatus" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="英文剩余课时" v-if="item.value === 'englishRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="英文剩余额度" v-if="item.value === 'englishSharedRemaining'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishSharedRemainingStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishSharedRemainingEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="英文当前级别" v-if="item.value === 'currentEnglishLevel'">
              <a-cascader
                allowClear
                :field-names="{ label: 'name', value: 'code', children: 'items' }"
                :options="optionsArr.currentEnglishLevel"
                placeholder="请选择"
                @change="onChangeEnglishLevel"
                v-model="currentEnglishLevelArr"
              />
            </a-form-item>
            <a-form-item label="英文学习规划师" v-if="item.value === 'englishConsultant'">
              <a-select
                v-model="queryParam.englishConsultant"
                show-search
                allowClear
                @search="searchWXTeacher"
                placeholder="请选择"
              >
                <a-select-option v-for="(item, index) in teacherList" :key="index" :value="item.uuid">{{
                  item.fullName
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="英文推荐赠课" v-if="item.value === 'englishGiftQrCode'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishGiftQrCodeStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishGiftQrCodeEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="英文购买课时" v-if="item.value === 'englishPurchased'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishPurchasedStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishPurchasedEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="英文社交赠课" v-if="item.value === 'englishGiftSocialNetworkShare'">
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishGiftSocialNetworkShareStart"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
              <span class="divider-line">-</span>
              <a-input-number
                style="width: 40%"
                v-model="queryParam.englishCourseUnit.englishGiftSocialNetworkShareEnd"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>

            <a-form-item label="英文班级" v-if="item.value === 'currentEnglishClassName'">
              <a-input
                v-model="queryParam.currentEnglishClassName"
                placeholder="请输入"
                allowClear
                @pressEnter="initSearchData('search')"
              />
            </a-form-item>
            <a-form-item label="英文入学时间" v-if="item.value === 'englishEntranceDate'">
              <a-range-picker @change="onChangeEnglishEntranceDate" v-model="englishEntranceDateArr" allowClear />
            </a-form-item>
            <a-form-item label="英文首课时间" v-if="item.value === 'englishFirstLessonDate'">
              <a-range-picker @change="onChangeEnglishFirstLessonDate" v-model="englishFirstLessonDateArr" allowClear />
            </a-form-item>

            <a-form-item label="英补升权益" v-if="item.value === 'englishLevelUp'">
              <a-select v-model="queryParam.englishLevelUp" placeholder="请选择" allowClear>
                <a-select-option v-for="(item, index) in optionsArr.englishLevelUp" :key="index" :value="item.value">{{
                  item.label
                }}</a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item label="订阅状态" v-if="item.value === 'subscriptionStatus'">
              <a-cascader allowClear :options="subscribeTree" placeholder="请选择" @change="subscriptionStatusChange" />
            </a-form-item>
          </a-col>
          <!-- 分层2.0 新增字段 -->
          <a-col :span="6" v-for="field in effectiveFields" :key="field.value" data-a="11">
            <!-- text -->
            <!-- <a-form-item :label="field.label" v-if="field.type === 'text'">
              <a-input
                v-model="queryParams[field.value]"
                allowClear
                placeholder="请输入"
                @pressEnter="initSearchData('search')"
              />
            </a-form-item> -->
            <!-- select -->
            <a-form-item :label="field.label" v-if="field.type === 'select'">
              <a-select
                show-search
                allowClear
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="queryParams[field.value]"
                placeholder="请选择"
              >
                <a-select-option
                  v-for="(item, index) in optionsArr[field.remoteOptionsKey]"
                  :key="index"
                  :value="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <!-- numberRange -->
            <a-form-item :label="field.label" v-if="field.type === 'numberRange'">
              <NumberRange v-model="queryParams[field.value]" />
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24" style="float: right; text-align: right">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button
                type="primary"
                @click="
                  () => {
                    initSearchData('search');
                  }
                "
                >查询</a-button
              >
              <a-button style="margin-left: 8px" @click="resetSearchData">重置</a-button>
              <a @click="setNavScreen" style="margin-left: 8px"> 更多 </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="stable-wrap">
      <div class="set-wrap">
        <a-dropdown placement="bottomCenter">
          <a-button type="primary" ghost style="margin-right: 10px">新建群发</a-button>
          <a-menu slot="overlay">
            <a-menu-item key="FILTER">
              <a @click="() => handleMassTextingJump('FILTER')">筛选结果</a>
            </a-menu-item>
            <a-menu-item key="IMPORT">
              <a @click="() => handleMassTextingJump('IMPORT')">导入名单</a>
            </a-menu-item>
            <a-menu-item key="RESULT">
              <a @click="() => handleMassTextingJump('RESULT')">群发结果</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <img @click="showHeaderFun" src="@/assets/newImage/set-icon.png" class="set-icon" />
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
        :loading="loading"
        @change="handleTableChange"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <!--跳转过去未登录-->
        <router-link
          slot="fullName"
          slot-scope="name, record"
          :to="{ path: '/studentDetail', query: { uuid: record.uuid } }"
        >
          {{ `${name}（${record.code}）` }}
        </router-link>
        <!-- <span slot="fullName" slot-scope="name, record" @click="hrefStudent(record)">
          {{ `${name}（${record.code}）` }}
        </span> -->

        <span slot="timezone" slot-scope="timezone">
          {{ getTimezoneName(timezone) }}
        </span>
        <span slot="reseller" slot-scope="reseller">
          {{ reseller ? '是' : '否' }}
        </span>
        <span slot="homeworkPrefer" slot-scope="homework">
          {{ initOptionsType(homework, optionsArr.homeworkPreferType) }}
        </span>
        <span slot="gender" slot-scope="gender">
          {{ gender ? (gender === 'F' ? '女' : gender === 'M' ? '男' : '不确定') : '-' }}
        </span>
        <span slot="country" slot-scope="country">
          {{ (countryOptions.find((item) => item.value === country) || {}).label || country }}
        </span>
      </a-table>
    </div>
    <TableHeaderScreening
      v-if="showHeaderTc"
      :selColumns="columns"
      :initScreen="initColumns"
      @setScreenArr="setScreenArr"
      @cancel="cancelTableHeader"
    ></TableHeaderScreening>
    <NavScreen
      v-if="showNavScreen"
      :selHeader="selHeader"
      :initHeader="initHeader"
      @cancel="cancelNavScreen"
      @setParamsSearch="setParamsSearch"
    ></NavScreen>
  </div>
</template>

<script>
import storage from 'store';
import moment from 'moment';
import { LocationCascader, LocationTreeSelect } from '@/utils/geoLocation';
import { getTimezoneName } from '@/utils/geoLocation';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import TableHeaderScreening from './TableHeaderScreening';
import NavScreen from './NavScreen.vue';
import newMassDelivery from './newMassDelivery';
import { filterFields, layer2Fields } from './home/constants';
import { NumberRange } from '@/components';
import { getUserUUID } from '@/utils/user';
import { toBMPPage } from '@/utils/util';
import { reportEvent } from '@/utils/gatherer';
import { isEmpty } from 'ramda';
import {
  queryStudentListNew,
  setStudentColumns,
  queryStudentColumns,
  queryScreenOption,
  getHeadmasterList,
  wxAccountTeacher,
} from '@/api/headTeacher';
import queryString from 'query-string';
import { getCountryOptions } from '@/utils/geoLocation';

const _token = storage.get(ACCESS_TOKEN);

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

// 分层2.0 参数转换
const transferLayer2Params = (params) => {
  if (!params || !(params instanceof Object)) return;
  const result = {
    // 选项
    tagEqConditions: [],
    // 范围
    tagRangeConditions: [],
  };
  for (const key in params) {
    const field = layer2Fields.find((field) => field.value === key);
    if (!field) {
      continue;
    }
    const [subject, name] = key.split('_');
    switch (field.type) {
      // 选项
      case 'select':
        params[key] &&
          result.tagEqConditions.push({
            name,
            subject: subject.toUpperCase(),
            value: params[key],
            valueType: 'TEXT',
          });
        break;
      // 范围
      case 'numberRange':
        params[key]?.some((v) => v !== null && v !== undefined) &&
          result.tagRangeConditions.push({
            name,
            subject: subject.toUpperCase(),
            minValue: params[key]?.[0],
            maxValue: params[key]?.[1],
            valueType: 'NUMBER',
          });
        break;
    }
  }
  return result;
};

const getEfftiveFieldsByKeys = (fields, keys) => {
  // return fields.filter((field) => keys.includes(field.value)).map((field) => ({ ...field, disabled: true }));
  return fields.reduce((p = [], v) => {
    if (keys.includes(v.value)) {
      return [
        ...p,
        {
          ...v,
          disabled: true,
        },
      ];
    }
    return p;
  }, []);
};

export default {
  name: 'studentIndex',
  components: {
    TableHeaderScreening,
    NavScreen,
    newMassDelivery,
    NumberRange,
    LocationCascader,
    LocationTreeSelect,
  },
  data() {
    return {
      getCountryOptions,
      getUserUUID,
      reportEvent,
      layer2Fields,
      // layer 2.0 查询参数
      queryParams: {},
      currentFields: {},
      getTimezoneName,
      languageArr: [
        { label: '中文', value: '中文' },
        { label: '中文(粤语)', value: '中文(粤语)' },
        { label: '英语', value: '英语' },
        { label: '日语', value: '日语' },
        { label: '韩语', value: '韩语' },
        { label: '法语', value: '法语' },
        { label: '德语', value: '德语' },
        { label: '印尼语', value: '印尼语' },
        { label: '其他', value: '其他' },
      ],
      subscribeTree: [
        {
          label: '中文',
          value: 'CHINESE',
          children: [
            {
              label: '已订阅',
              value: 'SUBSCRIBED',
            },
            {
              label: '已取消',
              value: 'CANCELLED',
            },
          ],
        },
        {
          label: '数学',
          value: 'MATH',
          children: [
            {
              label: '已订阅',
              value: 'SUBSCRIBED',
            },
            {
              label: '已取消',
              value: 'CANCELLED',
            },
          ],
        },
        {
          label: '英文',
          value: 'ENGLISH',
          children: [
            {
              label: '已订阅',
              value: 'SUBSCRIBED',
            },
            {
              label: '已取消',
              value: 'CANCELLED',
            },
          ],
        },
      ],

      batchParams: {},
      batchStudent: 0,
      loadingBatch: false,
      loadingBtn: false,
      roleArr: storage.get('roleArr'),
      userInfo: storage.get('userInfo'),
      iconLoading: false,

      sendType: 0,
      studentNum: 0,
      studentBindNum: 0,
      exclusiveKey: '',
      noBindCodes: [],
      showLoading: true,

      showDate: false,
      remindVal: 1,
      uploading: false,
      previewImage: [],
      previewVisible: false,
      fileList: [],
      showDelivery: false,
      rechargeType: '',
      rank: '',
      birthTimeArr: [],
      mathFirstLessonDateArr: [],
      currentMathLevelArr: [],
      currentEnglishLevelArr: [],

      chineseFirstLessonDateArr: [],
      mathFirstLessonDate: [],
      mathEntranceDateArr: [],
      englishEntranceDateArr: [],

      chineseEntranceDateArr: [],
      currentChineseLevelArr: [],
      tableSorter: {},
      loading: false,
      formItemLayout,
      formTailLayout,
      teacherList: [],
      headmasterList: [],
      optionsArr: [],
      selHeader: [
        { label: '学生', value: 'name', disabled: true },
        { label: '国别号', value: 'mobile', disabled: true },
        { label: '国家/地区', value: 'email', disabled: true },
        { label: '中文学生状态', value: 'chineseStatus', disabled: true },
        { label: '中文剩余课时', value: 'chineseRemaining', disabled: true },
        { label: '中文剩余额度', value: 'chineseSharedRemaining', disabled: true },
        { label: '中文当前级别', value: 'currentChineseLevel', disabled: true },
      ],
      // 表头的全部下拉
      initHeader: [...filterFields, ...layer2Fields],
      filterConditions: [],
      showFieldsColumns: [],
      showSelectNav: [],
      current: 0,
      pageSize: 10,
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      tableData: [],
      showNavScreen: false,
      showHeaderTc: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        chineseCourseUnit: {},
        mathCourseUnit: {},
        englishCourseUnit: {},
        // 分层2.0 text 查询类型
        tagEqConditions: [],
        // 分层2.0 number range 查询类型
        tagRangeConditions: [],
        countryStateCities: [],
        subscriptionStatus: undefined,
      },
      // 表头
      serialNum: {
        title: '序号',
        key: 'index',
        width: 60,
        fixed: 'left',
        dataIndex: 'num',
        customRender: (text, record, index) =>
          `${(this.initPagination.current - 1) * this.initPagination.pageSize + (index + 1)}`,
      },
      countryOptions: [],
      initColumns: [
        {
          title: '学生',
          dataIndex: 'fullName',
          key: 'fullName',
          scopedSlots: { customRender: 'fullName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              // on: {
              //   // 鼠标单击行
              //   click: () => {

              //     this.$router.push({ path: '/studentDetail', query: { uuid: record.uuid } });
              //   },
              // },
            };
          },
        },
        {
          title: '手机',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: '家长微信昵称',
          dataIndex: 'wxName',
          key: 'wxName',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: '时区',
          dataIndex: 'timezone',
          key: 'timezone',
          scopedSlots: { customRender: 'timezone' },
        },
        {
          title: '国别号',
          dataIndex: 'countryCode',
          key: 'countryCode',
        },
        {
          title: '国家/地区',
          dataIndex: 'country',
          key: 'countryStateCities',
          scopedSlots: { customRender: 'country' },
        },
        {
          title: '城市',
          dataIndex: 'city',
          key: 'city',
        },
        {
          title: '分销大使',
          dataIndex: 'reseller',
          key: 'reseller',
          scopedSlots: { customRender: 'reseller' },
        },
        {
          title: '性别',
          dataIndex: 'gender',
          key: 'gender',
          scopedSlots: { customRender: 'gender' },
        },
        {
          title: '生日',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth',
        },
        {
          title: '英文昵称',
          dataIndex: 'englishName',
          key: 'englishName',
        },
        {
          title: '当前年龄',
          dataIndex: 'currentAge',
          key: 'currentAge',
        },
        {
          title: '中文学生状态',
          dataIndex: 'chineseStatus',
          key: 'chineseStatus',
        },
        {
          title: '中文剩余课时',
          dataIndex: 'chineseRemaining',
          key: 'chineseRemaining',
          sorter: true,
        },
        {
          title: '中文剩余额度',
          dataIndex: 'chineseSharedRemaining',
          key: 'chineseSharedRemaining',
          sorter: true,
        },
        {
          title: '中文当前级别',
          dataIndex: 'chineseCurrentSchedule.currentLevel',
          key: 'chineseCurrentLevel',
        },
        {
          title: '中文班主任',
          dataIndex: 'chineseClassAdmin.fullName',
          key: 'chineseClassAdminName',
        },
        {
          title: '中文学习规划师',
          dataIndex: 'chineseConsultant.name',
          key: 'chineseConsultantName',
        },
        {
          title: '中文推荐赠课',
          dataIndex: 'chineseGiftQrCode',
          key: 'chineseGiftQrCode',
          sorter: true,
        },
        {
          title: '中文购买课时',
          dataIndex: 'chinesePurchased',
          key: 'chinesePurchased',
          sorter: true,
        },
        {
          title: '中文社交赠课',
          dataIndex: 'chineseGiftSocialNetworkShare',
          key: 'chineseGiftSocialNetworkShare',
          sorter: true,
        },
        {
          title: '中文班型',
          dataIndex: 'chineseCurrentSchedule.currentClassType',
          key: 'chineseCurrentClassType',
        },
        {
          title: '中文班级',
          dataIndex: 'chineseCurrentSchedule.currentClassName',
          key: 'chineseCurrentClassName',
        },
        {
          title: '中文入学时间',
          dataIndex: 'chineseEntranceDate',
          key: 'chineseEntranceDate',
        },
        {
          title: '中文首课时间',
          dataIndex: 'chineseFirstLessonDate',
          key: 'chineseFirstLessonDate',
        },
        {
          title: '中文补升权益',
          dataIndex: 'chineseLevelUp',
          key: 'chineseLevelUp',
        },
        {
          title: '数学学生状态',
          dataIndex: 'mathStatus',
          key: 'mathStatus',
        },
        {
          title: '数学剩余课时',
          dataIndex: 'mathRemaining',
          key: 'mathRemaining',
          sorter: true,
        },
        {
          title: '数学剩余额度',
          dataIndex: 'mathSharedRemaining',
          key: 'mathSharedRemaining',
          sorter: true,
        },
        {
          title: '数学当前级别',
          dataIndex: 'mathCurrentSchedule.currentLevel',
          key: 'mathCurrentLevel',
        },
        {
          title: '数学辅导老师',
          dataIndex: 'mathClassAdmin.fullName',
          key: 'mathClassAdminName',
        },
        {
          title: '数学学习规划师',
          dataIndex: 'mathConsultant.name',
          key: 'mathConsultantName',
        },
        {
          title: '数学推荐赠课',
          dataIndex: 'mathGiftQrCode',
          key: 'mathGiftQrCode',
          sorter: true,
        },
        {
          title: '数学购买课时',
          dataIndex: 'mathPurchased',
          key: 'mathPurchased',
          sorter: true,
        },
        {
          title: '数学社交赠课',
          dataIndex: 'mathGiftSocialNetworkShare',
          key: 'mathGiftSocialNetworkShare',
          sorter: true,
        },
        {
          title: '数学班型',
          dataIndex: 'mathCurrentSchedule.currentClassType',
          key: 'mathCurrentClassType',
        },
        {
          title: '数学班级',
          dataIndex: 'mathCurrentSchedule.currentClassName',
          key: 'mathCurrentClassName',
        },
        {
          title: '数学入学时间',
          dataIndex: 'mathEntranceDate',
          key: 'mathEntranceDate',
        },
        {
          title: '数学首课时间',
          dataIndex: 'mathFirstLessonDate',
          key: 'mathFirstLessonDate',
        },
        {
          title: '沟通方式偏好',
          dataIndex: 'preferCommunicateWay',
          key: 'preferCommunicateWay',
        },
        {
          title: '数学补升权益',
          dataIndex: 'mathLevelUp',
          key: 'mathLevelUp',
        },
        {
          title: '中文作业偏好',
          dataIndex: 'chineseHomeworkPrefer',
          key: 'chineseHomeworkPrefer',
          scopedSlots: { customRender: 'homeworkPrefer' },
        },
        {
          title: '数学作业偏好',
          dataIndex: 'mathHomeworkPrefer',
          key: 'mathHomeworkPrefer',
          scopedSlots: { customRender: 'homeworkPrefer' },
        },

        {
          title: '英文学生状态',
          dataIndex: 'englishStatus',
          key: 'englishStatus',
        },
        {
          title: '英文剩余课时',
          dataIndex: 'englishRemaining',
          key: 'englishRemaining',
          sorter: true,
        },
        {
          title: '英文剩余额度',
          dataIndex: 'englishSharedRemaining',
          key: 'englishSharedRemaining',
          sorter: true,
        },
        {
          title: '英文当前级别',
          dataIndex: 'englishCurrentSchedule.currentLevel',
          key: 'englishCurrentLevel',
        },
        {
          title: '英文辅导老师',
          dataIndex: 'englishClassAdmin.fullName',
          key: 'englishClassAdminName',
        },
        {
          title: '英文学习规划师',
          dataIndex: 'englishConsultant.name',
          key: 'englishConsultantName',
        },
        {
          title: '英文推荐赠课',
          dataIndex: 'englishGiftQrCode',
          key: 'englishGiftQrCode',
          sorter: true,
        },
        {
          title: '英文购买课时',
          dataIndex: 'englishPurchased',
          key: 'englishPurchased',
          sorter: true,
        },
        {
          title: '英文社交赠课',
          dataIndex: 'englishGiftSocialNetworkShare',
          key: 'englishGiftSocialNetworkShare',
          sorter: true,
        },
        {
          title: '英文班级',
          dataIndex: 'englishCurrentSchedule.currentClassName',
          key: 'englishCurrentClassName',
        },
        {
          title: '英文入学时间',
          dataIndex: 'englishEntranceDate',
          key: 'englishEntranceDate',
        },
        {
          title: '英文首课时间',
          dataIndex: 'englishFirstLessonDate',
          key: 'englishFirstLessonDate',
        },

        {
          title: '英文补升权益',
          dataIndex: 'englishLevelUp',
          key: 'englishLevelUp',
        },
      ],

      columns: [
        {
          title: '学生',
          dataIndex: 'fullName',
          key: 'fullName',
          scopedSlots: { customRender: 'fullName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                color: '#3372FE',
                cursor: 'pointer',
              },
              // on: {
              //   // 鼠标单击行
              //   click: () => {
              //     // const { fullName, code, uuid, timezoneStd, timezone, email, classAdmin } = record;
              //     // const conversation_student = {
              //     //   fullName,
              //     //   code,
              //     //   uuid,
              //     //   timezoneStd,
              //     //   timezone,
              //     //   email,
              //     //   classAdmin,
              //     // };
              //     // // 记录选中学生信息
              //     // session.set('studentProfile', conversation_student);
              //     this.$router.push({ path: '/studentDetail', query: { uuid: record.uuid } });
              //   },
              // },
            };
          },
        },
        {
          title: '手机',
          dataIndex: 'mobile',
          key: 'mobile',
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: '中文剩余课时',
          dataIndex: 'chineseRemaining',
          key: 'chineseRemaining',
          sorter: true,
        },
        {
          title: '中文剩余额度',
          dataIndex: 'chineseSharedRemaining',
          key: 'chineseSharedRemaining',
          sorter: true,
        },
        {
          title: '数学剩余课时',
          dataIndex: 'mathRemaining',
          key: 'mathRemaining',
          sorter: true,
        },
        {
          title: '数学剩余额度',
          dataIndex: 'mathSharedRemaining',
          key: 'mathSharedRemaining',
          sorter: true,
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
    };
  },

  created() {
    // this.searchHeadmaster();
    this.queryScreenOption();
  },
  mounted() {
    getCountryOptions().then((res) => {
      this.countryOptions = res;
    });
    this.columns.unshift(this.serialNum);
  },

  activated() {
    if (this.$route.query.notShardStudent) {
      this.initSearchData();
    }
    // 判断是不是从首页点击用户分层数据跳转过来的
    this.rank = this.$route.query.rank ? this.$route.query.rank : '';
    this.rechargeType = this.$route.query.rechargeType ? this.$route.query.rechargeType : '';
    // 从首页进来，清空所有的筛选条件
    if (this.rank || this.rechargeType) {
      this.resetSearchData('home');
    }
    // 查询是否有自定义
    this.queryStudentColumns();

    // 代表从组件模块点击他的学生
    if (this.$route.params.name) {
      this.queryParam.classAdminId = this.$route.params.uuid;
      this.searchHeadmaster(this.$route.params.name);
    }
    // 只有班主任角色才去查询，其他角色手动查询
    if (
      !(
        this.userInfo.roleArr.includes('TEACHING_STAFF') ||
        this.userInfo.roleArr.includes('TEACHING_STAFF_ADMIN') ||
        this.userInfo.roleArr.includes('HEAD_TEACHER_SUPER')
      )
    ) {
      this.initSearchData();
    }
  },
  computed: {
    effectiveFields() {
      return layer2Fields.filter((field) => (this.selHeader ?? []).some((head) => head.value === field.value));
    },
    otherFields() {
      return this.selHeader.filter((h) => !(this.layer2Fields ?? []).some((f) => f.value === h.value));
    },
    isClassAdminOrCrmCC() {
      return this.userInfo.roleArr.includes('CLASS_ADMIN') || this.userInfo.roleArr.includes('CRM_CC');
    },
  },
  methods: {
    subscriptionStatusChange(e) {
      if (e?.length) {
        this.queryParam.subscriptionStatus = {
          subject: e[0],
          status: e[1],
        };
      } else {
        this.queryParam.subscriptionStatus = undefined;
      }
    },
    handleMassTextingJump(type) {
      const handlerMap = {
        FILTER: () => {
          if (!Object.keys(this.queryParam ?? {}).length) {
            this.$message.error(t('请携带查询参数查询后再进行群发'));
            return;
          }
          if (this.initPagination.total > 20000) {
            this.$message.error('查询结果超过20000条，请缩小查询范围');
            return;
          }
          if (this.initPagination.total <= 0) {
            this.$message.error('查询结果为0，请切换筛选条件');
            return;
          }

          const search = queryString.stringify({
            searchJsonString: JSON.stringify(this.currentFields),
            type: 'FILTER',
            origin: 'CA_STUDIO',
            token: _token,
            excutorId: this.isClassAdminOrCrmCC ? this.userInfo.userID : undefined,
          });

          toBMPPage(`/create-mass-texting?${search}`, '_blank');
        },
        IMPORT: () => {
          const search = queryString.stringify({
            type: 'IMPORT',
            origin: 'CA_STUDIO',
            token: _token,
            excutorId: this.isClassAdminOrCrmCC ? this.userInfo.userID : undefined,
          });

          toBMPPage(`/create-mass-texting?${search}`, '_blank');
        },
        RESULT: () => {
          const search = queryString.stringify({
            token: _token,
          });

          toBMPPage(`/mass-texting-list?${search}`, '_blank');
        },
      };
      handlerMap[type]();
    },
    timezonesChange(e) {
      if (!e?.length) {
        this.queryParam.timezones = undefined;
        return;
      }
      this.queryParam.timezones = e;
    },
    closeDelivery() {
      this.showDelivery = false;
    },
    // 批量下载海报
    batchDownload() {
      this.loadingBatch = true;
      this.initSearchData('search', 'batch');
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    hrefStudent(record) {
      const routeUrl = this.$router.resolve({
        path: '/studentDetail',
        query: { uuid: record.uuid },
      });
      window.open(routeUrl.href, '_blank');
      //  this.$router.push({ path: '/studentDetail', query: { uuid: record.uuid } });
    },
    onChangeChineseLevel(val) {
      if (val.length > 1) {
        this.queryParam.currentChineseLevel = val[1];
      } else {
        this.queryParam.currentChineseLevel = '';
      }
    },
    onChangeMathLevel(val) {
      if (val.length > 1) {
        this.queryParam.currentMathLevel = val[1];
      } else {
        this.queryParam.currentMathLevel = '';
      }
    },
    onChangeEnglishLevel(val) {
      if (val.length > 1) {
        this.queryParam.currentEnglishLevel = val[1];
      } else {
        this.queryParam.currentEnglishLevel = '';
      }
    },
    handleTableChange(data, filters, sorter) {
      this.tableSorter = sorter;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.initSearchData();
    },
    resetNavData() {
      this.birthTimeArr = [];
      this.currentMathLevelArr = [];
      this.currentEnglishLevelArr = [];
      this.mathFirstLessonDateArr = [];
      this.chineseFirstLessonDateArr = [];
      this.currentChineseLevelArr = [];
      this.chineseEntranceDateArr = [];
      this.mathEntranceDateArr = [];
      this.englishEntranceDateArr = [];

      this.queryParam = {
        chineseCourseUnit: {},
        mathCourseUnit: {},
        englishCourseUnit: {},
      };
    },
    cleanQueryParams() {
      for (const key in this.queryParams) {
        if (Object.hasOwnProperty.call(this.queryParams, key)) {
          this.queryParams[key] = undefined;
        }
      }
    },
    resetSearchData(type) {
      this.birthTimeArr = [];
      this.currentMathLevelArr = [];
      this.currentEnglishLevelArr = [];
      this.mathFirstLessonDateArr = [];
      this.chineseFirstLessonDateArr = [];
      this.currentChineseLevelArr = [];
      this.chineseEntranceDateArr = [];
      this.mathEntranceDateArr = [];
      this.englishEntranceDateArr = [];
      this.queryParam = {
        chineseCourseUnit: {},
        mathCourseUnit: {},
        englishCourseUnit: {},
      };
      this.cleanQueryParams();
      if (type) {
        // 代表从首页分层进来，
        this.pageNum = 1;
        this.initSearchData();
      } else {
        this.initSearchData('search');
      }
    },
    searchWXTeacher(name) {
      const params = {
        name,
      };
      wxAccountTeacher(params).then((res) => {
        this.teacherList = res.data.content;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    // 搜索班主任
    searchHeadmaster(name) {
      const params = {
        roles: ['CLASS_ADMIN'],
        name,
      };
      getHeadmasterList(params).then((res) => {
        this.headmasterList = res.data.content;
      });
    },
    onChangeMathEntranceDate(date) {
      if (date.length > 0) {
        this.queryParam.mathEntranceDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.mathEntranceDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.mathEntranceDateStartTime = null;
        this.queryParam.mathEntranceDateEndTime = null;
      }
    },
    onChangeEnglishEntranceDate(date) {
      if (date.length > 0) {
        this.queryParam.englishEntranceDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.englishEntranceDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.englishEntranceDateStartTime = null;
        this.queryParam.englishEntranceDateEndTime = null;
      }
    },
    onChangeChineseEntranceDate(date) {
      if (date.length > 0) {
        this.queryParam.chineseEntranceDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.chineseEntranceDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.chineseEntranceDateEndTime = null;
        this.queryParam.chineseEntranceDateEndTime = null;
      }
    },
    onChangeMathFirstLessonDate(date) {
      if (date.length > 0) {
        this.queryParam.mathFirstLessonDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.mathFirstLessonDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.mathFirstLessonDateStartTime = null;
        this.queryParam.mathFirstLessonDateEndTime = null;
      }
    },
    onChangeEnglishFirstLessonDate(date) {
      if (date.length > 0) {
        this.queryParam.englishFirstLessonDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.englishFirstLessonDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.englishFirstLessonDateStartTime = null;
        this.queryParam.englishFirstLessonDateEndTime = null;
      }
    },
    onChangeChineseFirstLessonDate(date) {
      if (date.length > 0) {
        this.queryParam.chineseFirstLessonDateStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.chineseFirstLessonDateEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.chineseFirstLessonDateStartTime = null;
        this.queryParam.chineseFirstLessonDateEndTime = null;
      }
    },
    onChangeBirthTime(date) {
      if (date.length > 0) {
        this.queryParam.dateOfBirthStartTime = date[0].format('YYYY-MM-DD');
        this.queryParam.dateOfBirthEndTime = date[1].format('YYYY-MM-DD');
      } else {
        this.queryParam.dateOfBirthStartTime = null;
        this.queryParam.dateOfBirthEndTime = null;
      }
    },

    // 获取下来项
    queryScreenOption() {
      queryScreenOption().then((res) => {
        this.optionsArr = res.data.content;
      });
    },
    // 找出两个数组相同的元素
    compare(arr1, arr2) {
      const arr = [];

      arr1.map((key) => {
        arr2.map((item) => {
          if (item.key === key) {
            arr.push(item);
          }
        });
      });
      return arr;
    },
    // 查询设置的表格
    queryStudentColumns() {
      queryStudentColumns().then((res) => {
        const data = res.data.content;
        // 过滤出两个数组
        if (data?.showFields && data?.showFields.length > 0) {
          const setColums = this.compare(data?.showFields, this.initColumns);
          this.columns = setColums;
          this.columns.unshift(this.serialNum);
        }
        // 设置筛选
        if (data?.filterConditions && data?.filterConditions?.length > 0) {
          this.selHeader = getEfftiveFieldsByKeys(this.initHeader, data.filterConditions);
        }
      });
    },
    // 保存自定义的列或者筛选
    async setStudentColumns(type, arr) {
      // 自定义列
      if (type === 'columns') {
        this.showFieldsColumns = arr;
      } else {
        this.showFieldsColumns = [];
      }
      // 筛选
      if (type === 'screen') {
        this.filterConditions = arr;
      } else {
        this.filterConditions = [];
      }
      const params = {
        filterConditions: this.filterConditions, // 筛选的
        showFields: this.showFieldsColumns.map((item) => {
          return item.key;
        }), // 自定义列
      };
      await setStudentColumns(params);
      this.$message.success('设置成功');
      // 自定义列表
      if (type === 'columns') {
        this.columns = [...this.showFieldsColumns];
      }
      if (type === 'screen') {
        this.selHeader = getEfftiveFieldsByKeys(this.initHeader, this.filterConditions);
        this.resetNavData();
      }
    },
    // 自定义表头
    setScreenArr(arr) {
      this.cancelTableHeader();
      this.setStudentColumns('columns', arr);
    },
    // 接收自定义的筛选
    async setParamsSearch(arr) {
      this.queryParam = {
        chineseCourseUnit: {},
        mathCourseUnit: {},
        englishCourseUnit: {},
      };
      await this.setStudentColumns('screen', arr);
      this.cancelNavScreen();
    },
    initSearchData(act, type) {
      this.loading = true;
      if (act === 'search') {
        this.pageNum = 1;
        this.rank = '';
        this.rechargeType = '';
      }

      // 从首页用户分层，续费分类跳过来的
      const params = { ...this.queryParam };

      params.rechargeType = this.rechargeType;
      params.rank = this.rank;
      params.pageNum = this.pageNum;
      params.pageSize = this.pageSize;
      params.orderField = this.tableSorter.field;

      // 如果是从未分享学员过来的
      if (this.$route.query.notShardStudent) {
        params.notShardStudent = JSON.parse(this.$route.query.notShardStudent);
      }
      if (this.tableSorter.order === 'descend') {
        params.sort = 'desc';
      } else if (this.tableSorter.order === 'ascend') {
        params.sort = 'asc';
      }
      this.batchParams = params;
      const p = { ...params, ...transferLayer2Params(this.queryParams) };

      this.currentFields = { ...p };

      delete this.currentFields.rechargeType;
      delete this.currentFields.rank;
      delete this.currentFields.pageNum;
      delete this.currentFields.pageSize;
      delete this.currentFields.orderField;

      queryStudentListNew(p).then((res) => {
        this.tableData = res.data.content;
        this.current = res.data.currentPage;

        this.pageSize = res.data.pageSize;
        this.batchStudent = res.data.totalCount;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
        this.loading = false;
        // 如果是批量下载
        if (type === 'batch') {
          this.loadingBatch = false;
          this.$router.push({
            path: '/studentposter1',
            query: {
              pageType: 'studentList',
              batchStudent: this.batchStudent,
              batchParams: JSON.stringify(this.batchParams),
            },
          });
        }
      });
      reportEvent({
        data: {
          screen_name: Object.keys(params)
            .filter((key) => !isEmpty(params[key]) && params[key] !== undefined)
            .filter((key) => !['pageNum', 'pageSize'].includes(key))
            .join(','),
          _event: 'CAstudio_StuCheckClick',
          User_id: getUserUUID(),
        },
      });
    },
    cancelNavScreen() {
      this.showNavScreen = false;
    },
    setNavScreen() {
      this.showNavScreen = true;
    },
    cancelTableHeader() {
      this.showHeaderTc = false;
    },
    showHeaderFun() {
      this.showHeaderTc = true;
    },

    handleOk() {},

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },

    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-select-selection--multiple {
  height: 32px;
  overflow-y: auto;
}
/deep/ .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #fafafa;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 125px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.stable-wrap {
  background-color: #fff;
  padding: 16px 20px;
}
.set-wrap {
  padding-bottom: 16px;
  text-align: right;
}
.set-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-bottom: 14px;
}
/deep/ .ant-btn-primary {
  box-shadow: none;
}
.divider-line {
  padding: 0 12px;
}
</style>
