<template>
  <a-modal width="800px" v-model="visible" title="自定义字段" @cancel="cancel" @ok="handleOk">
    <div class="header-wrap">
      <div class="left-content">
        <a-input placeholder="搜索想要的字段" v-model="searchVal" allowClear />
        <div class="tag-wrap">
          <a-tooltip v-for="item in initScreen" :key="item.key">
            <template slot="title">
              {{ item.title }}
            </template>
            <div
              class="item-tag"
              @click="onSelected(item)"
              :class="[
                selected
                  .map((item) => {
                    return item.key;
                  })
                  .includes(item.key)
                  ? 'selected'
                  : '',
                searchVal && item.title.indexOf(searchVal) !== -1 && !selected.includes(item) ? 'search' : '',
              ]"
            >
              {{ item.title }}
            </div>
          </a-tooltip>
        </div>
      </div>
      <div class="right-content">
        <div class="list-title">当前列表展示</div>
        <transition-group name="drag" class="list-wrap" tag="ul">
          <li
            @dragenter="dragenter($event, index)"
            @dragover="dragover($event, index)"
            @dragstart="dragstart(index)"
            draggable
            v-for="(item, index) in headerList"
            :key="item.key"
          >
            {{ item.title }}
          </li>
        </transition-group>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'TableHeaderScreening',
  props: {
    initScreen: {
      // 总的列表
      type: Array,
      default: () => [],
    },
    selColumns: {
      // 已选择的
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: true,
      searchVal: '',
      selected: [],
      headerList: [],
      dragIndex: '',
      enterIndex: '',
    };
  },
  methods: {
    shuffle() {
      this.headerList = this.$shuffle(this.headerList);
    },
    dragstart(index) {
      this.dragIndex = index;
    },
    dragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (this.dragIndex !== index) {
        const moving = this.headerList[this.dragIndex];
        this.headerList.splice(this.dragIndex, 1);
        this.headerList.splice(index, 0, moving);
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex = index;
      }
    },
    dragover(e) {
      e.preventDefault();
    },

    handleOk() {
      this.$emit('setScreenArr', this.headerList);
    },
    onSelected(item) {
      if (
        this.selected
          .map((select) => {
            return select.key;
          })
          .includes(item.key)
      ) {
        const filterList = this.selected.filter((res) => res.key !== item.key);
        this.selected = filterList;
      } else {
        const len = this.selected.length + 1;
        if (len > 9) {
          this.$message.error('最多选择8个展示项!');
          return;
        }
        this.selected = [...this.selected, item];
      }
      this.headerList = [...this.selected];
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  created() {
    this.selected = [...this.selColumns];
    this.headerList = [...this.selColumns];
  },
};
</script>

<style scoped lang="less">
.header-wrap {
  display: flex;
}
.left-content {
  flex: 3;
  height: 500px;
  border: 1px solid #e8e8e8;
  margin-right: 8px;
  border-radius: 8px;
  padding: 8px;
}
.right-content {
  flex: 1;
  height: 500px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}
.tag-wrap {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  height: 430px;
  overflow-y: auto;
}
.item-tag {
  text-align: left;
  margin: 0 3px;
  border: 1px solid #f2f2f2;
  cursor: pointer;
  border-radius: 3px;
  width: 102px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 8px;
  margin-bottom: 10px;
  &.selected {
    background: #43d186;
    color: #fff;
  }
  &.search {
    color: #43d186;
    border: 1px solid #43d186;
  }
}
.list-wrap {
  padding: 8px;
  .drag-move {
    transition: transform 0.3s;
  }

  li {
    border-radius: 3px;
    padding: 2px 10px;
    background: rgb(242, 242, 242);
    color: rgb(0, 0, 0);
    cursor: move;
    margin: 10px 0px;
  }
}
.list-title {
  padding: 8px 12px 0;
  font-size: 14px;
  font-weight: 500;
}
</style>
