<template>
  <div style="display: inline-flex">
    <a-input-number
      style="width: 40%"
      v-model="range[0]"
      placeholder="请输入"
      allowClear
      @change="handleChange"
      @pressEnter="handlePressEnter"
    />
    <span style="margin: 0 12px">-</span>
    <a-input-number
      style="width: 40%"
      v-model="range[1]"
      placeholder="请输入"
      @change="handleChange"
      allowClear
      @pressEnter="handlePressEnter"
    />
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: Array },
  },
  data() {
    return {
      range: [undefined, undefined],
    };
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.range = [];
        return;
      }
      this.range = newValue;
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.range);
    },
    handlePressEnter() {
      this.$emit('pressEnter');
    },
  },
};
</script>
<style></style>
