const layer2BaseFields = [
  {
    label: '学习稳定得分',
    value: 'studyStabilityScore',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '学员黏性得分',
    value: 'studentRetentionScore',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '续费机会得分',
    value: 'renewalOpportunityScore',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '推荐机会得分',
    value: 'recommendationOpportunityScore',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '流失风险得分',
    value: 'churnRiskScore',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '学龄',
    value: 'studentAge',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '上上周完课',
    value: 'completedCourses2WeeksAgo',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '上周完课',
    value: 'completedCoursesLastWeek',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '本周已上已约',
    value: 'scheduledCoursesThisWeek',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '下周已约',
    value: 'scheduledCoursesNextWeek',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '剩余课时可学时长',
    value: 'remainingCourseHours',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '复购次数',
    value: 'repurchaseCount',
    disabled: false,
    type: 'numberRange',
  },
  {
    label: '续费特征池',
    value: 'renewalFeaturePool',
    disabled: false,
    type: 'select',
    remoteOptionsKey: 'renewalFeaturePool',
  },
  {
    label: '是否多娃',
    value: 'hasMultipleChildren',
    disabled: false,
    type: 'select',
    remoteOptionsKey: 'hasMultipleChildren',
  },
  {
    label: '是否拓科',
    value: 'hasTakenOtherSubjects',
    disabled: false,
    type: 'select',
    remoteOptionsKey: 'hasTakenOtherSubjects',
  },
];

const extendFields = (baseFields, courses) => {
  if (!Array.isArray(baseFields)) return;
  return baseFields.reduce((p, v) => {
    return [
      ...p,
      ...courses.map(({ text, tailKey }) => ({
        ...v,
        label: `${text}${v.label}`,
        value: `${tailKey}_${v.value}`,
        remoteOptionsKey: `${tailKey}_${v.remoteOptionsKey}`,
      })),
    ];
  }, []);
};

const layer2Fields = extendFields(layer2BaseFields, [
  {
    text: '中文',
    tailKey: 'chinese',
  },
  {
    text: '英语',
    tailKey: 'english',
  },
  {
    text: '数学',
    tailKey: 'math',
  },
]);

const filterFields = [
  { label: '学生', value: 'name', disabled: false },
  { label: '手机', value: 'mobile', disabled: false },
  { label: '邮箱', value: 'email', disabled: false },
  // { label: '家长微信昵称', value: 'wxName', disabled: false },
  { label: '时区', value: 'timezones', disabled: false },
  { label: '国别号', value: 'countryCode', disabled: false },
  { label: '国家/地区', value: 'countryStateCities', disabled: false },
  { label: '沟通语言', value: 'communicationLanguages', disabled: false },
  { label: '城市', value: 'city', disabled: false },
  { label: '性别', value: 'gender', disabled: false },
  { label: '生日', value: 'birthDate', disabled: false },
  { label: '分销大使', value: 'reseller', disabled: false },
  { label: '当前年龄', value: 'currentAge', disabled: false },
  { label: '中文学生状态', value: 'chineseStatus', disabled: false },
  { label: '中文剩余课时', value: 'chineseRemaining', disabled: false },
  { label: '中文剩余额度', value: 'chineseSharedRemaining', disabled: false },
  { label: '中文当前级别', value: 'currentChineseLevel', disabled: false },
  { label: '班主任', value: 'classAdminId', disabled: false },
  { label: '中文学习规划师', value: 'chineseConsultant', disabled: false },
  { label: '中文推荐赠课', value: 'chineseGiftQrCode', disabled: false },
  { label: '中文购买课时', value: 'chinesePurchased', disabled: false },
  { label: '中文社交赠课', value: 'chineseGiftSocialNetworkShare', disabled: false },
  { label: '中文班型', value: 'currentChineseClassType', disabled: false },
  { label: '中文班级', value: 'currentChineseClassName', disabled: false },
  { label: '中文入学时间', value: 'chineseEntranceDate', disabled: false },
  { label: '中文首课时间', value: 'chineseFirstLessonDate', disabled: false },
  { label: '数学学生状态', value: 'mathStatus', disabled: false },
  { label: '数学剩余课时', value: 'mathRemaining', disabled: false },
  { label: '数学剩余额度', value: 'mathSharedRemaining', disabled: false },
  { label: '数学当前级别', value: 'currentMathLevel', disabled: false },
  { label: '数学学习规划师', value: 'mathConsultant', disabled: false },
  { label: '数学推荐赠课', value: 'mathGiftQrCode', disabled: false },
  { label: '数学购买课时', value: 'mathPurchased', disabled: false },
  { label: '数学社交赠课', value: 'mathGiftSocialNetworkShare', disabled: false },
  { label: '数学班型', value: 'currentMathClassType', disabled: false },
  { label: '数学班级', value: 'currentMathClassName', disabled: false },
  { label: '数学入学时间', value: 'mathEntranceDate', disabled: false },
  { label: '数学首课时间', value: 'mathFirstLessonDate', disabled: false },
  { label: '沟通方式偏好', value: 'preferCommunicateWay', disabled: false },
  { label: '中文补升权益', value: 'chineseLevelUp', disabled: false },
  { label: '数学补升权益', value: 'mathLevelUp', disabled: false },
  { label: '中文作业偏好', value: 'chineseHomeworkPrefer', disabled: false },
  { label: '数学作业偏好', value: 'mathHomeworkPrefer', disabled: false },
  // { label: '中文跟进策略', value: 'chineseFollowStrategy', disabled: false },
  // { label: '中文近3周课消', value: 'chineseThreeWeekCourseUnitUsed', disabled: false },
  // { label: '中文续费池', value: 'chineseRenewalPool', disabled: false },
  // { label: '中文作业提交率', value: 'chineseHomeworkSubRate', disabled: false },
  // { label: '中文作业正确率', value: 'chineseHomeworkCorrectRate', disabled: false },
  // { label: '中文本周分享', value: 'chineseCurrentWeekShare', disabled: false },
  // { label: '中文近3个月进线', value: 'chineseThreeMonthLines', disabled: false },
  // { label: '中文更换班主任数', value: 'chineseChangeClassAdminTimes', disabled: false },
  // { label: '数学跟进策略', value: 'mathFollowStrategy', disabled: false },
  // { label: '数学近3周课消', value: 'mathThreeWeekCourseUnitUsed', disabled: false },
  // { label: '数学续费池', value: 'mathRenewalPool', disabled: false },
  // { label: '数学作业提交率', value: 'mathHomeworkSubRate', disabled: false },
  // { label: '数学作业正确率', value: 'mathHomeworkCorrectRate', disabled: false },
  // { label: '数学本周分享', value: 'mathCurrentWeekShare', disabled: false },
  // { label: '数学近3个月进线', value: 'mathThreeMonthLines', disabled: false },
  // { label: '数学更换班主任数', value: 'mathChangeClassAdminTimes', disabled: false },
  { label: '英文学生状态', value: 'englishStatus', disabled: false },
  { label: '英文剩余课时', value: 'englishRemaining', disabled: false },
  { label: '英文剩余额度', value: 'englishSharedRemaining', disabled: false },
  { label: '英文当前级别', value: 'currentEnglishLevel', disabled: false },
  { label: '英文学习规划师', value: 'englishConsultant', disabled: false },
  { label: '英文推荐赠课', value: 'englishGiftQrCode', disabled: false },
  { label: '英文购买课时', value: 'englishPurchased', disabled: false },
  { label: '英文社交赠课', value: 'englishGiftSocialNetworkShare', disabled: false },
  { label: '英文班级', value: 'currentEnglishClassName', disabled: false },
  { label: '英文入学时间', value: 'englishEntranceDate', disabled: false },
  { label: '英文首课时间', value: 'englishFirstLessonDate', disabled: false },
  { label: '订阅状态', value: 'subscriptionStatus', disabled: false },
];

export { filterFields, layer2Fields };
