<template>
  <a-modal
    title="新建群发"
    :maskClosable="false"
    :okButtonProps="{
      props: {
        loading: iconLoading,
      },
    }"
    width="640px"
    :visible="showVisible"
    @ok="saveDelivery"
    @cancel="cancelDelivery"
    okText="确定"
    cancelText="取消"
  >
    <div class="delivery-wrap">
      <div v-if="showLoading" class="tip-txt">群发人群数据正在请求中，您可先编辑群发内容，等待数据请求完成...</div>
      <div class="delivery-item">
        <div class="delivery-label">
          送达人数
          <a-popover trigger="click">
            <template slot="content">
              <div v-if="fromOrigin === 'studentIndex'">
                送达人数：当前登录班主任列表服务学员中绑定微信数量 / 当前登录班主任列表查询结果的服务学员数量
              </div>
              <div v-if="fromOrigin === 'homework'">
                送达人数：当前登录班主任本次可发送学员中绑定微信数量 / 当前登录班主任作业列表对应的学员数量
              </div>
            </template>
            <i class="explain" v-if="!showLoading"></i>
          </a-popover>
          <a-spin v-if="showLoading">
            <a-icon slot="indicator" type="loading" style="font-size: 14px" spin />
          </a-spin>
          ：
        </div>
        <div class="delivery-con" v-if="showLoading">-</div>
        <div class="delivery-con" v-else style="display: flex; justify-content: space-between">
          <span v-if="fromOrigin === 'studentIndex'">{{ studentBindNum }} / {{ studentNum }}</span>
          <span v-if="fromOrigin === 'homework'">{{ receiverCount }} / {{ totalPeople }}</span>
          <a-popover v-if="fromOrigin === 'homework'">
            <template slot="content">
              <div>未送达学员<span>（未加企微好友/已加好友，但未绑定学号）</span></div>
              <div style="margin-top: 15px; width: 400px">
                <span v-for="(item, index) in noBindCodes" :key="index"
                  >{{ item }}<span v-if="noBindCodes.length !== index + 1">、</span></span
                >
              </div>
            </template>
            <span style="color: #04cb94">查看未送达学员</span>
          </a-popover>
        </div>
      </div>
      <!-- <div class="delivery-item">
                <div class="delivery-label">
                    执行员工数
                  <a-popover  trigger="hover">
                      <template slot="content">
                     实际执行员工数（即最近添加客户好友的员工数量）/当前线索Owner的数量
                      </template>
                      <i class="explain"></i>
                  </a-popover>： </div>
                    <div class="delivery-con">1/2222</div>
              </div> -->
      <div class="delivery-item">
        <div class="delivery-label"><span style="color: red; padding: 0 4px">*</span>群发内容：</div>
        <div class="delivery-con wechatFromWrapper">
          <div
            class="editor"
            contentEditable
            ref="editRef"
            @blur="getPosition"
            @click="getPosition"
            @paste="handlePaste($event)"
            @input="editChange"
          ></div>
          <div>
            <a-icon
              type="smile"
              @click="setShowEmoji($event)"
              style="font-size: 24px; color: rgba(0, 0, 0, 0.4); margin-top: 8px; cursor: pointer"
              theme="outlined"
            />
          </div>
          <ul class="emojiBox" :style="{ display: showEmoji ? 'block' : 'none' }">
            <li
              v-for="(item, index) in emojis"
              :key="item.cn"
              :data-cn="item.cn"
              class="emojiItem"
              :style="item.style"
              @click="emojiCheck($event)"
            ></li>
          </ul>
        </div>
      </div>
      <!-- <div class="delivery-item">
                <div class="delivery-label"></div>
                <div class="delivery-con">
                  <a-checkbox v-model="isMiniProgram">附带专属海报小程序</a-checkbox>
                </div>
              </div> -->
      <div class="delivery-item">
        <div class="delivery-label">群发图片：</div>
        <div class="delivery-con">
          <a-upload
            name="file"
            accept=".png,.jpg,.jpeg"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChangePic"
            :customRequest="handleRequest"
            @preview="handlePreview"
          >
            <div v-if="fileList.length < 9">
              <a-icon :type="uploading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelPic">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </div>
      <div class="delivery-item">
        <div class="delivery-label">发送类型：</div>
        <div class="delivery-con">
          <a-radio-group v-model="sendType" @change="onChangeRemind">
            <a-radio :value="0"> 立即提醒 </a-radio>
            <a-radio :value="1"> 定时提醒 </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="delivery-item" v-show="showDate">
        <div class="delivery-label"><span style="color: red; padding: 0 4px">*</span>发送时间：</div>
        <div class="delivery-con">
          <a-date-picker
            style="width: 100%"
            format="YYYY-MM-DD HH:mm"
            :show-time="{ format: 'HH:mm' }"
            placeholder="请选择"
            v-model="sendTime"
          />
        </div>
      </div>
      <div style="font-size: 14px; margin-left: 49px; color: red">
        注意：受企业微信限制，每个学员每天只可接收1条群发消息；群发消息需要在企业微信点击确认发送；
      </div>
    </div>
  </a-modal>
</template>

<script>
import { uploadGroupFile, sendGroupApi } from '@/api/headTeacher';
import { getEmojis } from 'wechat-emoji-parser';
import emojiSprite from '@/assets/emoji-sprite.png';
import coverImg from '@/assets/cover.png';

export default {
  name: 'newMassDelivery',
  props: [
    'studentNum',
    'studentBindNum',
    'noBindCodes',
    'exclusiveKey',
    'fromOrigin',
    'totalPeople',
    'receiverCount',
    'receiverIds',
    'senderId',
  ],
  data() {
    return {
      showVisible: true,
      fileList: [],
      showDate: false,
      remindVal: 1,
      uploading: false,
      previewImage: [],
      previewVisible: false,
      lastEditRangeRef: null,
      emojis: [],
      showEmoji: false,
      iconLoading: false,
      groupContent: '',
      isMiniProgram: false,
      sendTime: null,
      sendType: 0,

      showLoading: false,
    };
  },
  methods: {
    handlePaste(e) {
      const clipdata = e.clipboardData;
      const types = [...(e.clipboardData.items || [])]?.map((item) => item.type) || [];

      if (types.includes('text/plain') && types.includes('text/html')) {
        e.preventDefault();
        document.execCommand('insertText', false, clipdata.getData('text/plain'));
      }

      if (types.some((item) => item.includes('image'))) {
        e.preventDefault();
      }
    },
    handleText(s) {
      return s
        .replace(/<div>/g, '\n')
        .replace(/<p>/g, '\n')
        .replace(/<\/div>/g, '')
        .replace(/<br\/?>/g, '')
        .replace(/&nbsp;/g, '');
    },
    getPosition() {
      const lastEditSelection = getSelection();

      this.lastEditRangeRef = lastEditSelection?.getRangeAt(0);
    },
    uploadGroupFile(formData, file) {
      this.iconLoading = true;
      uploadGroupFile(formData).then((res) => {
        this.iconLoading = false;
        let data = res.data.data;
        try {
          this.fileList = this.fileList.map((item) => {
            if (item.uid === file.uid) {
              return {
                ...item,
                url: data.url,
                uid: data.mediaId,
                name: `${data.id}`,
                status: 'done',
              };
            }
            return item;
          });
        } catch {
          this.iconLoading = false;
          this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
        }
      });
    },
    handleRequest({ file }) {
      const formData = new FormData();
      formData.append('file', file);

      this.uploadGroupFile(formData, file);
    },
    onChangeRemind(e) {
      let val = e.target.value;
      this.sendType = val;
      if (val === 1) {
        this.showDate = true;
      } else {
        this.sendTime = null;
        console.log(this.sendTime);
        this.showDate = false;
      }
    },
    handleCancelPic() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangePic(info) {
      this.fileList = info.fileList;
    },

    handleCancelPic() {
      this.previewVisible = false;
    },
    editChange() {
      const text = this.handleText(this.$refs.editRef.innerHTML || '');

      const div = document.createElement('div');
      div.innerHTML = text;
      const list = [...div.getElementsByTagName('li')];

      list.forEach((item) => {
        const t = document.createTextNode(item.getAttribute('data-cn') || '');
        div.replaceChild(t, item);
      });
      this.groupContent = div.innerHTML;
      //onChange?.(div.innerHTML);
    },

    insertAfter(newElement, targetElement) {
      const parent = targetElement.parentNode;
      if (parent.lastChild === targetElement) {
        parent.appendChild(newElement);
      } else {
        parent.insertBefore(newElement, targetElement.nextSibling);
      }
    },

    emojiCheck(e) {
      const content = e.target.cloneNode();
      content.style.margin = '0';
      content.setAttribute('contenteditable', 'false');
      // 编辑框设置焦点
      this.$refs.editRef?.focus();
      // 获取选定对象
      const selection = getSelection();
      // 判断是否有最后光标对象存在
      if (this.lastEditRangeRef) {
        // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
        selection.removeAllRanges();
        selection.addRange(this.lastEditRangeRef);
      }
      const parent1 = selection.anchorNode;
      // 判断选定对象范围是编辑框还是文本节点
      if (selection.anchorNode.nodeName !== '#text') {
        // 如果是编辑框范围。则创建表情文本节点进行插入
        if (parent1.childNodes.length > 0) {
          // 如果文本框的子元素大于0，则表示有其他元素，则按照位置插入表情节点
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < parent1.childNodes.length; i++) {
            if (i === selection.anchorOffset) {
              parent1.insertBefore(content, parent1.childNodes[i]);
            }
          }
        } else {
          // 否则直接插入一个表情元素
          parent1.appendChild(content);
        }
      } else {
        // 如果是文本节点则先获取光标对象
        const range = getSelection().getRangeAt(0);
        // 获取光标对象的范围界定对象，一般就是textNode对象
        const textNode = range.startContainer;
        // 获取光标位置
        const rangeStartOffset = range.startOffset;
        // 截取文本，重新插入元素
        const textNode1 = document.createTextNode(textNode.data.substr(0, rangeStartOffset));
        const textNode2 = document.createTextNode(textNode.data.substr(rangeStartOffset));
        const parent = textNode.parentNode;
        const nextNode = textNode.nextSibling;
        textNode.remove();
        if (nextNode) {
          parent.insertBefore(textNode1, nextNode);
        } else {
          parent.appendChild(textNode1);
        }
        this.insertAfter(content, textNode1);
        this.insertAfter(textNode2, content);
      }
      // 创建新的光标对象
      const range = document.createRange();
      const space = document.createTextNode('');
      // edit.appendChild(space);
      this.insertAfter(space, content);
      // 光标对象的范围界定为表情节点后的空格
      range.selectNodeContents(space);
      space.deleteData(0, 1);
      // 把光标位置定位表情节点后的空格里
      range.setStart(space, 0);
      range.setEnd(space, 0);
      // 使光标开始和光标结束重叠
      range.collapse(true);
      // 清除选定对象的所有光标对象
      selection.removeAllRanges();
      // 插入新的光标对象
      selection.addRange(range);
      // 无论如何都要记录最后光标对象
      if (selection.type !== 'None') {
        this.lastEditRangeRef = selection.getRangeAt(0);
      }
      this.editChange();
    },
    getEmojisAll() {
      this.emojis = getEmojis({ size: 24, emojiSpriteUrl: emojiSprite }).map((item) => {
        const style = {};
        Object.keys(item.style).forEach((i = '') => {
          // 转驼峰命名
          const k = i
            .split('-')
            .map((i, idx) => {
              if (idx === 0) return i;
              const [first, ...rest] = i || '';
              return first.toUpperCase() + rest.join('');
            })
            .join('');
          style[k] = item.style[i];
        });
        return { ...item, style };
      });
    },
    setShowEmoji(e) {
      e.stopPropagation();
      this.showEmoji = !this.showEmoji;
    },
    closeEmoji() {
      this.showEmoji = false;
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) return '';
      ctx.drawImage(img, 0, 0, img.width, img.height);
      const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
      const dataURL = canvas.toDataURL(`image/${ext}`);
      return dataURL;
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr?.[0].match(/:(.*?);/)?.[1] || '';
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async saveDelivery() {
      if (!this.groupContent) {
        this.$message.warning('请输入群发内容');
        return false;
      }
      if (!this.studentBindNum && !this.receiverCount) {
        this.$message.warning('送达人数为0，无法发送');
        return false;
      }
      if (this.sendType === 1 && !this.sendTime) {
        this.$message.warning('发送时间不能为空');
        return false;
      }
      if (this.sendType === 1 && this.sendTime && new Date(this.sendTime).getTime() < new Date().getTime() + 3600000) {
        this.$message.warning('发送时间距离当前时间不能小于1小时');
        return false;
      }
      let miniprogramParams = {
        mediaId: '',
        url: '',
      };
      // 如果需要附带小程序，则上传图片
      if (this.isMiniProgram) {
        const img = document.createElement('img');
        img.src = coverImg;
        img.crossOrigin = '';
        await new Promise((resolve) => (img.onload = () => resolve()));
        const base64 = this.getBase64Image(img);
        const file = this.dataURLtoFile(base64, 'file');
        const formData = new FormData();
        formData.append('file', file);
        this.iconLoading = true;
        const dataInfo = await uploadGroupFile(formData);

        miniprogramParams = {
          mediaId: dataInfo.data.data.mediaId,
          url: dataInfo.data.data.url,
        };
      }
      let imgList = this.fileList.map((item) => ({
        fileId: +item.name,
        media_id: item.uid,
        pic_url: item.url || '',
      }));

      const messageContent = {
        text: {
          content: this.groupContent,
        },
        ...(this.isMiniProgram
          ? {
              miniprogram: {
                title: '专属海报',
                appid: 'wxf90629987fd1df93',
                page: 'pages/free-course/createPoster',
                picUrl: miniprogramParams.url,
                pic_media_id: miniprogramParams.mediaId,
              },
            }
          : {}),
        attachments: (imgList || []).map((item) => ({
          msgtype: 'image',
          image: item,
        })),
      };
      if (this.sendType === 0) {
        this.sendTime = new Date();
      }
      const searchParams = {
        studentIndex: {
          exclusiveKey: this.exclusiveKey,
          sendType: this.sendType,
          messageContent: JSON.stringify(messageContent),
          sendTime: this.sendTime && this.$moment(this.sendTime).format('YYYY-MM-DD HH:mm:ss'),
        },
        homework: {
          senderId: this.senderId,
          receiverIds: this.receiverIds,
          sendType: this.sendType,
          messageContent: JSON.stringify(messageContent),
          sendTime: this.sendTime && this.$moment(this.sendTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      }[this.fromOrigin];

      this.iconLoading = true;
      await sendGroupApi(searchParams, this.fromOrigin === 'studentIndex')
        .then((res) => {
          this.$message.success('群发成功');
          this.iconLoading = false;
          this.cancelDelivery();
        })
        .catch((error) => {
          this.iconLoading = false;
        });
    },
    cancelDelivery() {
      this.groupContent = '';
      this.fileList = [];
      this.sendType = 0;
      this.showDate = false;
      this.isMiniProgram = false;
      this.$emit('closeDelivery');
    },
  },
  mounted() {
    // 预加载emoji图片
    const img = document.createElement('img');
    img.src = emojiSprite;
    document.addEventListener('click', this.closeEmoji);
    this.getEmojisAll();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeEmoji);
  },
};
</script>

<style lang="less" scoped>
.explain {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('~@/assets/explain.png') no-repeat center;
  background-size: 100%;
  margin: 2px 4px 0;
  cursor: pointer;
}
.delivery-item {
  display: flex;
  margin-bottom: 14px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.delivery-label {
  width: 120px;
  justify-content: right;
  display: flex;
}
.delivery-con {
  flex: 1;
}
.wechatFromWrapper {
  position: relative;

  .editor {
    max-width: 100%;
    min-height: 120px;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    &:hover {
      border-color: #ff6661;
      border-right-width: 1px;
    }
    &:focus {
      border-color: #ff6661;
      border-right-width: 1px;
      outline: 0;
      box-shadow: 0 0 0 2px rgb(255 57 57 / 20%);
      transition: 0.3s;
    }
  }
  .emojiBox {
    max-width: 420px;
    max-height: 200px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    border: 1px solid #dedede;
  }
  .emojiItem {
    margin: 4px;
    cursor: pointer;
  }
  .emojiBox {
    position: absolute;
    z-index: 9;
    background: #fff;
    border-radius: 2px;
  }
}
.tip-txt {
  font-size: 14px;
  color: rgb(150 146 146);
  margin-left: 37px;
  margin-bottom: 12px;
}
</style>
