<template>
  <a-modal width="440px" v-model="visible" title="设置筛选" @cancel="cancel" @ok="handleSubmit">
    <a-form :form="form" @submit="handleSubmit" @onValuesChange="changeForm">
      <a-form-item
        v-for="(k, index) in form.getFieldValue('keys')"
        :key="k"
        v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
        :required="false"
      >
        <a-select
          show-search
          :filter-option="filterOption"
          option-filter-prop="children"
          v-decorator="[`names[${k}]`, {}]"
          placeholder="请选择筛选项"
          @focus="setBeforeVal(k)"
          style="width: 300px; margin-right: 10px"
          @change="handleChange($event, k)"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in screenArr"
            :disabled="item.disabled"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-tooltip>
          <template slot="title"> 删除此行 </template>
          <a-icon
            style="font-size: 16px"
            type="delete"
            class="dynamic-delete-button"
            v-show="form.getFieldValue('keys').length > 1"
            @click="() => remove(k)"
          />
        </a-tooltip>
      </a-form-item>
      <a-form-item v-bind="formItemLayoutWithOutLabel">
        <a-button type="dashed" style="width: 300px" @click="add">
          <a-icon type="plus" />
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
let id = 0;
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

export default {
  props: {
    initHeader: {
      // 总的列表
      type: Array,
      default: () => [],
    },
    selHeader: {
      // 搜索页有的筛选
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: true,
      beforeVal: '',
      removeKey: '',
      screenArr: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        // wrapperCol: {
        //   xs: { span: 24, offset: 0 },
        //   sm: { span: 20, offset: 4 },
        // },
      },
    };
  },
  mounted() {
    this.screenArr = this.compare([...this.selHeader], [...this.initHeader]);

    this.screenArr.map((item) => {
      if (item.disabled) {
        const { form } = this;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);

        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
          keys: nextKeys,
        });
        this.form.getFieldDecorator(`names[${id - 1}]`, { initialValue: item.value, preserve: true });
      }
    });
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 吧初始化的下拉选择出
    compare(arr1, arr2) {
      arr1.map((item1) => {
        arr2.map((item) => {
          if (item.value === item1.value) {
            item.disabled = true;
          }
        });
      });
      return arr2;
    },
    setBeforeVal(val) {
      const { form } = this;
      const keys = form.getFieldValue('names');
      // 获取改变之前的值
      this.beforeVal = keys[val];
    },
    changeForm() {},
    cancel() {
      this.$emit('cancel');
    },

    handleChange(val) {
      this.screenArr.map((item) => {
        // 把改变之前的值设置为true
        if (item.value === this.beforeVal) {
          console.log(11);
          item.disabled = false;
        }
        if (item.value === val) {
          item.disabled = true;
        }
      });

      // setTimeout(() => {
      // const { form } = this;
      // const keys = form.getFieldValue('names');
      // });
    },
    remove(k) {
      const { form } = this;
      // can use data-binding to get
      const keys = form.getFieldValue('keys');
      // 删除的是那个字段
      const keyVal = form.getFieldValue(`names[${k}]`);

      // We need at least one passenger

      this.screenArr.map((item) => {
        if (item.value === keyVal) {
          item.disabled = false;
        }
      });
      // can use data-binding to set
      form.setFieldsValue({
        keys: keys.filter((key) => key !== k),
      });
    },

    add() {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      // if (keys.length > 9) {
      //   this.$message.warning('筛选条件不能超过10个');
      //   return false;
      // }
      const nextKeys = keys.concat(id++);
      form.setFieldsValue({
        keys: nextKeys,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          const { keys, names } = values;
          const arr = keys.map((key) => names[key]);
          this.$emit('setParamsSearch', arr);
          reportEvent({
            data: {
              screen_name: arr?.join(',')??'',
              _event: 'CAstudio_StuCheckSetClick',
              User_id: getUserUUID(),
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
